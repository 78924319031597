<template>
  <div class="mains">
    <div class="banner" style="position: relative;">
      <!-- <div style="
          position: absolute;
          font-size:60px;
          color:#e13e00;
          font-weight: 600;
          letter-spacing:5px;
          top: 50%;
          margin-top: -35px;"
      >中国国际经济技术合作协会</div> -->
      <img src="@/assets/bannerBg.png" alt="" />
    </div>
    <div class="menus">
      <ul>
        <li
          v-for="(item, i) in list"
          :key="i"
          @mouseenter="mouse(item.child.length, $event)"
          @mouseleave="esc(item.child.length, $event)"
        >
          <router-link :to="item.url">{{ item.name }}</router-link>
          <div class="lists">
            <div class="list" v-for="(k, j) in item.child" :key="j">
              <router-link :to="k.url">{{ k.name }}</router-link>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        { name: "首页", url: "/", child: [] },
        {
          name: "关于本会",
          url: "/association",
          child: [
            { name: "学会简介", url: "/association/associationindex" },
            { name: "工作条例", url: "/association/Constitution" },
            { name: "领导成员", url: "/association/Leadership" },
            // { name: "组织架构", url: "/association/Architecture" },
            // { name: "内设机构", url: "/association/Institution" },
          ],
        },
        {
          name: "新闻中心",
          url: "/dynamics",
          child: [
            { name: "头条新闻", url: "/dynamics?type=top" },
            { name: "国家新闻", url: "/dynamics?type=china" },
            { name: "地方要闻", url: "/dynamics?type=addr" },
            // { name: "图片新闻", url: "/dynamics?type=img" },
            // { name: "视频新闻", url: "/dynamics?type=video" },
          ],
        },
        {
          name: "政策法规",
          url: "/lawdoo",
          child: [
            { name: "中央文件", url: "/lawdoo?type=center" },
            // { name: "部委文件", url: "/lawdoo?type=buwei" },
            { name: "政策解读", url: "/lawdoo?type=zhengc" },
            { name: "其他文件", url: "/lawdoo?type=other" },
          ],
        },
        {
          name: "高研院",
          url: "/high",
          child: [
            // { name: "组织定位", url: "/high/highgoods" },
            { name: "组织简介", url: "/high/highindex" },
            { name: "业务框架", url: "/high/business" },
            { name: "组织动态", url: "/high/dynamic" },
            { name: "合作咨询", url: "/high/cooperation" },
          ],
        },
        // { name: "赛事报名", url: "/", child: [] },
        {
          name: "会员中心",
          url: "/member",
          child: [
              { name: "入会须知", url: "/member/memberDesc" },
            { name: "入会申请", url: "/member/MemberIndex" },
            // { name: "会员查询", url: "/member/member-sreach" },
          ],
        },
        // {
        //   name: "资讯动态",
        //   url: "/dynamics",
        //   child: [
        //     { name: "就业资讯", url: "/dynamics/advise" },
        //     { name: "就业政策", url: "/dynamics/policy" },
        //   ],
        // },
        { name: "项目合作",
          url: "/project",
          child: [
            { name: "证书认证", url: "/certificate" },
          ]
        },
        { name: "联系我们", url: "/contacut", child: [] },
      ],
    };
  },
  methods: {
    mouse(i, item) {
      item.target.children[1].style.height = i * 35 + "px";
    },
    esc(i, item) {
      item.target.children[1].style.height = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.mains {
  background-color: white;
  .menus {
    height: 60px;
    background-color: #e13e00;
    margin-top: -3px;
    ul {
      width: 1200px;
      margin: auto;
      display: flex;
      align-items: center;

      li {
        position: relative;
        text-align: center;
        height: 100%;
        line-height: 60px;
        flex: 1;

        a {
          color: white;
        }
        .lists {
          position: absolute;
          z-index: 12;
          background: #e13e00;
          height: 0;
          transition: all 0.6s;
          overflow: hidden;
          width: 100%;
          .list {
            height: 35px;
            line-height: 35px;
            min-width: 100%;
            text-align: center;
            color: white;
          }
        }
      }

      li:hover {
        background-color: rgba(0, 0, 0, 0.129);
      }
    }
  }

  .banner {
    width: 1200px;
    // height: 206px;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>

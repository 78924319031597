<template>
  <div id="app">
    <Headxh></Headxh>
    <router-view></router-view>
    <Footerxh></Footerxh>
  </div>
</template>

<script>
import Headxh from "./components/Headxh.vue";
import Footerxh from "./components/Footerxh.vue"
export default {
  name: 'App',
  components: {
    Headxh,Footerxh
  }
}
</script>

<style lang="scss">
//首行缩进
.ql-editor ,.editor {
  tab-size:7!important;
  -moz-tab-size:7;
  -o-tab-size:7;
}
.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 0!important;
    text-indent: 28px;
}
.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 0!important;
    text-indent: 52px;
}
.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 0!important;
    text-indent: 64px;
}
.ql-lineheight-1 {
  line-height: 1;
}

.ql-lineheight-1-5 {
  line-height: 1.5;
}

.ql-lineheight-1-75 {
  line-height: 1.75;
}

 .ql-lineheight-2 {
  line-height: 2;
}

.ql-lineheight-3 {
  line-height: 3;
}

 .ql-lineheight-4 {
  line-height: 4;
}

.ql-lineheight-5 {
  line-height: 5;
}
.body{
 .font dl{
  align-items: center;
 }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #FF8329;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #545454;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  /* border-radius: 5px; */
}
#app {
  background: #f5f5f5;
  height: auto !important;
  min-width: 1200px;
}
.menus {
   height: 60px;
   background-color: #e13e00;

   ul {
     width: 1200px;
     margin: auto;
     display: flex;
     align-items: center;

     li {
       text-align: center;
       height: 100%;
       line-height: 60px;
       flex: 1;

       a {
         color: white;
       }
     }

     li:hover {
       background-color: rgba(0, 0, 0, 0.129);
     }
   }
 }
 .el-pagination{
  text-align: center;
  margin-top: 15px;
  .el-pager li.active{
    background-color: #e13e00!important;
  }
  .el-pager li:hover{
    color:#e13e00!important;
  }
}
.el-pager, .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
  padding: 0px 10px!important;
}
.txt,.cont{
  p,image,div,img{
   max-width: 100%!important;
  }
}
.el-carousel__indicators--outside button{
  background-color: red!important;
}
</style>

<template>
  <div class="all">
    <div class="mains">
      <p> ©中促会人才发展工作委员会  版权所有</p>
      <p>成都市武侯区新飞翔大厦 <span>电话：18200378921</span></p>
      <p>京ICP备20006170号-4 Copyright 2023 All rights reserve</p>
      <p>网站建设：<a href="#" target="_blank">四川 · 成都 · 优软科技</a></p>
      <img src="https://www.capc.com.cn/uploadfiles/1498805180.jpg" alt="">
    </div>
  </div>

</template>

<script>
    export default {
        data () {
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>
  .all{
    background: white;
  }
  .mains{
    width: 1200px;
    margin: auto;
    position: relative;
    background: white;
    height: 150px;
    text-align: center;
    padding-top: 20px;
    font-size: 13px;
    p{
      text-align: center;
      line-height: 24px;
    }
    img{
      width: 111px;
      height: 111px;
      position: absolute;
      top: 20px;
      right: 125px;
    }
  }
</style>
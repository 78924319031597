import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
let arr = [
  {
    path: '/about',
    coms: 'Views/about',
    title: 'about',
    meta: {
      title: '关于我们'
    },
    component: () => import(`@/${e.coms}.vue`)
  }
]
const generatedRoutes = arr.map(e => {
  return {
    path: e.path,
    name: e.title,
    meta: e.meta,
    component: () => import(`@/${e.coms}.vue`)
  }
})

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '官网'
    },
    redirect: '/index',
  },
  {
    path: '/index',
    name: 'Home',
    meta: {
      title: '官网'
    },
    component: () => import('@/Views/index')
  },
  {
    path: "/member",
    name: "member",
    meta: {
      title: "会员中心",
    },
    component: () => import("@/Views/member-center/MemberCenter.vue"),
    redirect: "/member/memberDesc",
    children: [
      {
        path: "MemberIndex",
        name: "MemberIndex",
        meta: {
          title: "入会申请"
        },
        component: () => import("@/Views/member-center/MemberIndex.vue")
      },
      {
        path: "memberDesc",
        name: "memberDesc",
        meta: {
          title: "入会须知"
        },
        component: () => import("@/Views/member-center/memberDesc.vue")
      },
      {
        path: "member-sreach",
        name: "member-sreach",
        meta: {
          title: "会员查询"
        },
        component: () => import("@/Views/member-center/MemberSreach.vue")
      }
    ]
  },
  {
    path: "/project",
    name: "project",
    meta: {
      title: "项目合作",
    },
    component: () => import("@/Views/project/project-center.vue"),
    redirect: "/certificate",
    children: [
      {
        path: "/certificate",
        name: "certificate",
        meta: {
          title: "证书认证"
        },
        component: () => import("@/Views/project/certificate.vue")
      },
    ]
  },
  {
    path: '/contacut',
    name: "contacut",
    meta: {
      title: "联系我们"
    },
    component: () => import("@/Views/contacutuser/ContacutUser.vue"),
    redirect: "/contacut/user",
    children: [
      {
        path: 'user',
        name: 'user',
        meta: {
          title: '关于我们'
        },
        component: () => import("@/Views/contacutuser/ContacutIndex.vue")
      }
    ]
  },
  {
    path:'/high',
    name:'high',
    meta:{
      title:"高研院"
    },
    component:()=>import("@/Views/high/HighIndex.vue"),
    redirect: "/high/highgoods",
    children: [
      {
        path:"highindex",
        name:"highindex",
        meta:{
          title:'组织简介'
        },
        component:()=>import("@/Views/high/Intro.vue")
      },
      {
        path:"dynamic",
        name:"dynamic",
        meta:{
          title:'组织动态'
        },
        component:()=>import("@/Views/high/Dynamic.vue")
      },
      {
        path:"business",
        name:"business",
        meta:{
          title:'业务框架'
        },
        component:()=>import("@/Views/high/Dusiness.vue")
      },
      {
        path:"cooperation",
        name:"cooperation",
        meta:{
          title:'合作咨询'
        },
        component:()=>import("@/Views/high/Cooperation.vue")
      },
      {
        path:"highgoods",
        name:"highgoods",
        meta:{
          title:'组织定位'
        },
        component:()=>import("@/Views/high/highgoods.vue")
      },
    ]
  },
  {
    path: "/association",
    name: "association",
    meta: {
      title: "关于本会",
    },
    component: () => import("@/Views/association/Associationindex.vue"),
    redirect: "/association/associationindex",
    children: [
      {
        path: "associationindex",
        name: "associationindex",
        meta: {
          title: "学会简介"
        },
        component: () => import("@/Views/association/Associationstudy.vue")
      },
      {
        path: "Constitution",
        name: "Constitution",
        meta: {
          title: "工作条例"
        },
        component: () => import("@/Views/association/Constitution.vue")
      },
      {
        path: "Leadership",
        name: "Leadership",
        meta: {
          title: "领导成员"
        },
        component: () => import("@/Views/association/Leadership.vue")
      },
      {
        path: "Architecture",
        name: "Architecture",
        meta: {
          title: "组织架构"
        },
        component: () => import("@/Views/association/Architecture.vue")
      },
      {
        path: "Institution",
        name: "Institution",
        meta: {
          title: "内设机构"
        },
        component: () => import("@/Views/association/Institution.vue")
      },
    ]
  },
  {
    path: "/lawdoo",
    name: "lawdoo",
    meta: {
      title: "政策法规",
    },
    component: () => import('@/Views/lawdoo/index.vue'),
  },
  {
    path: "/dynamics",
    name: "dynamics",
    meta: {
      title: "新闻中心"
    },
    component: () => import("@/Views/dynamics/index.vue")
  },
  {
    path: "/detail",
    name: "detail",
    meta: {
      title: "新闻详情"
    },
    component: () => import("@/Views/dynamics/detail.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes: [...routes, ...generatedRoutes]
})
export default router

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import axios from 'axios';
import dayjs  from 'dayjs'
import router from './router'
import ElementUI from 'element-ui';
Vue.use(ElementUI);
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/normalize.css'
import './assets/css/reset.css'

document.title = '中促会人才发展工作委员会 '

Vue.config.productionTip = false
Vue.prototype.$dayjs = dayjs
// Vue.prototype.$store = store
Vue.prototype.$axios = axios;

import './lib/quill.core.css'
import './lib/quill.snow.css'
import './lib/quill.bubble.css'



new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
